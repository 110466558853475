import React from 'react';

import { Color } from '../ChessPiece';

export default function BoardInfo(props) {
    const { boardState } = props;

    const whiteCastles = boardState.getWhiteCastles();
    const blackCastles = boardState.getBlackCastles();

    const ep = boardState.getEnPassant();
    const epLabel = ep ? ep.label() : '';

    const halfMoves = boardState.getHalfMoveNumber();
    const fullMoves = boardState.getFullMoveNumber();

    const moveSide = boardState.getMoveSide() === Color.white() ? 'White' : 'Black';

    return (
        <div className="board-info">
            <span className="board-info-key">White Castles:</span>
            <span className="board-info-value">{whiteCastles}</span>
            <span className="board-info-key">Black Castles:</span>
            <span className="board-info-value">{blackCastles}</span>
            <span className="board-info-key">En Passant:</span>
            <span className="board-info-value">{epLabel}</span>
            <span className="board-info-key">Half Move:</span>
            <span className="board-info-value">{halfMoves}</span>
            <span className="board-info-key">Full Move:</span>
            <span className="board-info-value">{fullMoves}</span>
            <span className="board-info-key">Move Side:</span>
            <span className="board-info-value">{moveSide}</span>
        </div>
    );
}
