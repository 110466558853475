import React from 'react';

import Coord from '../ChessCoord';
import Piece from '../ChessPiece';

function gridSquareColor(sq) {
    if ((sq.row() + sq.col()) % 2 === 0) {
        return 'black';
    } else {
        return 'white';
    }
}

export default function BoardSquare(props) {
    const { coord, piece, selSq, onClick } = props;

    const gridColor = gridSquareColor(coord);

    const pieceName = Piece.isEmpty(piece)
        ? ''
        : (() => {
              const pieceColor = Piece.isWhite(piece) ? 'w' : 'b';
              const pieceKind = Piece.getKindChar(piece);
              return `piece-name piece-name-${pieceColor}${pieceKind}`;
          })();

    const isSelected = selSq !== null && Coord.equal(coord, selSq);
    const selectedAttr = isSelected ? 'selected-square' : '';

    const className = `board-square ${gridColor}-square ${pieceName} ${selectedAttr}`;

    const draggable = Piece.isEmpty(piece) ? 'false' : 'true';

    return <div className={className} draggable={draggable} onClick={(e) => onClick(coord)}></div>;
}
