import React from 'react';
import './App.css';

import Game from './component/Game';

export default function App() {
    return (
        <div className="app-container">
            <h1>Online Chess</h1>
            <Game></Game>
        </div>
    );
}
