import io from 'socket.io-client';
import React, { useEffect, useState } from 'react';

import Board from './Board';
import BoardInfo from './BoardInfo';
import MovesHistory from './MovesHistory';

import createChessState from '../ChessState';

const BACKEND_URL =
    process.env.NODE_ENV === 'production'
        ? 'https://chess-be.herokuapp.com/'
        : 'http://localhost:5000/';

export default function Game(props) {
    // The web-socket to the backend.
    //const [socket, setSocket] = useState(null);

    // const [game, setGame] = useState(null);

    // Register actions on web socket events.
    useEffect(() => {
        console.log('Connecting to the backend: ', BACKEND_URL);

        const s = io(BACKEND_URL, {
            reconnection: false,
        });

        s.on('connect', () => {
            console.log('Socket connected.');
        });

        s.on('authenticated', (auth) => {
            console.log('Authenticated: ', auth);
            s.emit('start-game', {});
        });

        //setSocket(s);
    }, []);

    // The history of all board states.
    const [boardHistory, setBoardHistory] = useState(() => {
        const initBoard = createChessState();
        return [initBoard];
    });

    // The history of all (half) moves.
    const [moveHistory, setMoveHistory] = useState([]);

    // The current index of the (half) moves.
    // -1 indicates no moves, that is, the initial board state.
    const [moveIndex, setMoveIndex] = useState(-1);

    const boardState = boardHistory[moveIndex + 1];

    const advance = (board, move) => {
        setBoardHistory((l) => l.concat(board));
        setMoveHistory((l) => l.concat(move));
        setMoveIndex((i) => i + 1);
    };

    const canAdvance = moveIndex === moveHistory.length - 1;

    const gotoHistory = (index) => {
        if (index < -1 || index > moveIndex.length) throw new Error('Invalid move index: ', index);
        setMoveIndex(index);
    };

    return (
        <div className="game-container">
            <BoardInfo boardState={boardState}></BoardInfo>
            <Board boardState={boardState} advance={advance} canAdvance={canAdvance}></Board>
            <MovesHistory
                moveHistory={moveHistory}
                moveIndex={moveIndex}
                gotoHistory={gotoHistory}
            ></MovesHistory>
        </div>
    );
}
