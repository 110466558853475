import React from 'react';

export default function MovesHistory(props) {
    const { moveHistory, moveIndex, gotoHistory } = props;

    const isEven = (x) => x % 2 === 0;

    const idx2key = (x) => {
        if (isEven(x)) {
            const number = x / 2 + 1;
            return `${number}a`;
        } else {
            const number = (x + 1) / 2;
            return `${number}b`;
        }
    };

    const moveNameClass = (x) => {
        if (x === moveIndex) return 'move-name move-name-current';
        else return 'move-name';
    };

    return (
        <div className="moves-history">
            {moveHistory.map((m, idx) => (
                <React.Fragment key={idx}>
                    {isEven(idx) && (
                        <span className="move-number" key={idx / 2 + 1}>
                            {idx / 2 + 1}.
                        </span>
                    )}
                    <span
                        className={moveNameClass(idx)}
                        key={idx2key(idx)}
                        onClick={(e) => gotoHistory(idx)}
                    >
                        {m.label()}
                    </span>
                </React.Fragment>
            ))}
        </div>
    );
}
