import Piece from './ChessPiece';

// FIXME:
//  - disambiguating moves
//  - promotions.
function toStr(piece, src, tgt, options) {
    if (options.isCastling) {
        // This is a castling move.
        if (tgt.col() > src.col()) return 'O-O';
        else return 'O-O-O';
    } else {
        const pieceText = Piece.isPawn(piece) ? '' : Piece.getKindChar(piece);

        const srcLabel = src.label();
        const isPawnCapture = Piece.isPawn(piece) && options.isCapture;
        const srcText = isPawnCapture ? srcLabel[0] : '';

        const tgtText = tgt.label();

        const captureTag = options.isCapture ? 'x' : '';
        const epTag = options.isEp ? 'e.p.' : '';
        const checkTag = options.isCheck ? '+' : '';

        return pieceText + srcText + captureTag + tgtText + epTag + checkTag;
    }
}

export default class Move {
    constructor(piece, src, tgt, options = {}) {
        this.piece = piece;
        this.src = src;
        this.tgt = tgt;
        this.options = options;
        this.str = toStr(piece, src, tgt, options);
    }

    source() {
        return this.src;
    }

    target() {
        return this.tgt;
    }

    label() {
        return this.str;
    }
}
