// Piece constants.
const P_EMPTY = 0;
const P_KING = 1;
const P_QUEEN = 2;
const P_ROOK = 3;
const P_BISHOP = 4;
const P_KNIGHT = 5;
const P_PAWN = 6;
const PIECE_MASK = 7;

// Color constants.
const COLOR_WHITE = 16;
const COLOR_BLACK = 32;
const COLOR_MASK = 16 | 32;

export class Color {
    static white() {
        return COLOR_WHITE;
    }
    static black() {
        return COLOR_BLACK;
    }

    static isWhite(color) {
        return color === COLOR_WHITE;
    }
    static isBlack(color) {
        return color === COLOR_BLACK;
    }

    // Return the opposite color.
    // Pre-condition: color is valid.
    static getOpposite(color) {
        return color ^ COLOR_MASK;
    }

    static parse(label) {
        switch (label) {
            case 'w':
                return COLOR_WHITE;
            case 'b':
                return COLOR_BLACK;
            default:
                throw new Error('Invalid color string: ' + label);
        }
    }
}

export default class Piece {
    static isEmpty(piece) {
        return piece === P_EMPTY;
    }

    static notEmpty(piece) {
        return piece !== P_EMPTY;
    }

    static makeEmpty() {
        return P_EMPTY;
    }

    // Pre-condition: piece is valid.
    static getColor(piece) {
        return piece & COLOR_MASK;
    }

    // Pre-condition: piece is valid.
    static getKind(piece) {
        return piece & PIECE_MASK;
    }

    static isWhite(piece) {
        return this.getColor(piece) === COLOR_WHITE;
    }

    static isBlack(piece) {
        return this.getColor(piece) === COLOR_BLACK;
    }

    static isKing(piece) {
        return this.getKind(piece) === P_KING;
    }

    static isQueen(piece) {
        return this.getKind(piece) === P_QUEEN;
    }

    static isRook(piece) {
        return this.getKind(piece) === P_ROOK;
    }

    static isBishop(piece) {
        return this.getKind(piece) === P_BISHOP;
    }

    static isKnight(piece) {
        return this.getKind(piece) === P_KNIGHT;
    }

    static isPawn(piece) {
        return this.getKind(piece) === P_PAWN;
    }

    static makeKing(color) {
        return P_KING | color;
    }

    static parse(ch) {
        const makePiece = (kind) => {
            if (ch === ch.toUpperCase()) return kind | COLOR_WHITE;
            else return kind | COLOR_BLACK;
        };

        switch (ch.toUpperCase()) {
            case 'K':
                return makePiece(P_KING);
            case 'Q':
                return makePiece(P_QUEEN);
            case 'R':
                return makePiece(P_ROOK);
            case 'B':
                return makePiece(P_BISHOP);
            case 'N':
                return makePiece(P_KNIGHT);
            case 'P':
                return makePiece(P_PAWN);
            default:
                throw new Error('Invalid piece: ' + ch);
        }
    }

    // Returns the kind only, so always upper case.
    static getKindChar(piece) {
        switch (this.getKind(piece)) {
            case P_KING:
                return 'K';
            case P_QUEEN:
                return 'Q';
            case P_ROOK:
                return 'R';
            case P_BISHOP:
                return 'B';
            case P_KNIGHT:
                return 'N';
            case P_PAWN:
                return 'P';
            default:
                throw new Error('Invalid input to getKindChar().');
        }
    }

    // Return the inverse of parse.
    static getPieceChar(piece) {
        const ch = this.getKindChar(piece);
        return this.isWhite(piece) ? ch : ch.toLowerCase();
    }
}
