export default class Coord {
    constructor(rowIdx, colIdx) {
        if (0 <= rowIdx && rowIdx <= 7 && 0 <= colIdx && colIdx <= 7) {
            //ok
        } else {
            throw new Error('Invalid Coord() parameters.');
        }
        this.rowIdx = rowIdx;
        this.colIdx = colIdx;
    }

    row() {
        return this.rowIdx;
    }

    col() {
        return this.colIdx;
    }

    static create(label) {
        if (label.length !== 2) {
            throw new Error('Invalid Coord.create() parameters.');
        }

        const rowId = label[1];
        const rowIdNumber = parseInt(rowId);
        if (isNaN(rowIdNumber)) {
            throw new Error('Invalid Coord.create() parameters.');
        }
        const rowIdx = rowIdNumber - 1;

        const colId = label[0];
        const colIdx = colId.charCodeAt(0) - 'a'.charCodeAt(0);
        if (!(0 <= colIdx && colIdx < 8)) {
            throw new Error('Invalid Coord.create() parameters.');
        }

        return new this(rowIdx, colIdx);
    }

    // Pre-conditions: lhs & rhs are both of the Coord type.
    static equal(lhs, rhs) {
        return lhs.row() === rhs.row() && lhs.col() === rhs.col();
    }

    // Returns the RowId, which is in the range of [1, 8].
    // FIXME: to be removed.
    getRowId() {
        return '12345678'.substr(this.row(), 1);
    }

    // Returns the ColId, which is in the range of [a-h].
    // FIXME: to be removed.
    getColId() {
        return 'abcdefgh'.substr(this.col(), 1);
    }

    // Return the label of the coord, eg, e4.
    // This is the inverse of create().
    label() {
        const col = 'abcdefgh'[this.col()];
        return `${col}${this.row() + 1}`;
    }
}
