import React, { useState } from 'react';

import BoardSquare from './BoardSquare';
import BoardEdge from './BoardEdge';

import Coord from '../ChessCoord';

// Handle click event on the board.
function handleBoardClick(boardState, advance, selSq, setSelSq, newSq) {
    //console.log(`handleBoardClick: [${newSq.label()}].`);

    // Case 1: do not already have a selected square/piece.
    if (selSq === null) {
        // If new square has a piece, and it is of the current moving side,
        // set it as the new select square/piece.
        if (boardState.canSelect(newSq)) {
            setSelSq(newSq);
            return;
        }
    }

    // Case 2: already have a selected square/piece.
    else {
        // Case 2.1: if it's the same square, de-select it.
        if (Coord.equal(newSq, selSq)) {
            setSelSq(null);
            return;
        }

        // Case 2.2: if the selected piece can move to the new square,
        // make the move and clear the selected square/piece.
        const moveResult = boardState.move(selSq, newSq);
        if (moveResult) {
            advance(moveResult.newBoard, moveResult.move);
            setSelSq(null);
            return;
        }

        // Case 2.3: check if we can select the new square/piece.
        if (boardState.canSelect(newSq)) {
            setSelSq(newSq);
            return;
        }

        // Case 2.4: do nothing.
    }
}

export default function Board(props) {
    const { boardState, advance, canAdvance } = props;

    // The currently selected square.
    const [selSq, setSelSq] = useState(null);

    const handleClick = (newSq) => {
        if (canAdvance) {
            return handleBoardClick(boardState, advance, selSq, setSelSq, newSq);
        }
    };

    const rows = [100, 7, 6, 5, 4, 3, 2, 1, 0, -100]; // top edge, all rows, bottom edge.
    const cols = [-100, 0, 1, 2, 3, 4, 5, 6, 7, 100]; // left edge, all columns, right edge.

    let grids = [];
    for (const r of rows) {
        for (const c of cols) {
            grids.push({ row: r, col: c });
        }
    }

    const renderGrid = (rowIdx, colIdx) => {
        const key = `${rowIdx}.${colIdx}`;

        if (Math.abs(rowIdx) === 100 || Math.abs(colIdx) === 100) {
            return <BoardEdge key={key}></BoardEdge>;
        } else {
            const coord = new Coord(rowIdx, colIdx);
            return (
                <BoardSquare
                    key={key}
                    coord={coord}
                    piece={boardState.getPiece(coord)}
                    selSq={selSq}
                    onClick={handleClick}
                ></BoardSquare>
            );
        }
    };

    return (
        <div className="board">
            {grids.map((g) => {
                return renderGrid(g.row, g.col);
            })}
        </div>
    );
}
