import Coord from './ChessCoord';
import Move from './ChessMove';
import Piece, { Color } from './ChessPiece';

const CASTLING_WHITE_KING = 1;
const CASTLING_WHITE_QUEEN = 2;
const CASTLING_BLACK_KING = 4;
const CASTLING_BLACK_QUEEN = 8;

const FEN_STARTING_POSITION = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

// Parse a line pieces according the FEN format.
function parsePieceLine(pieces_line) {
    const chars = pieces_line.split('');

    let pieces = [];

    for (const ch of chars) {
        const number = parseInt(ch);
        if (isNaN(number)) {
            pieces.push(Piece.parse(ch));
        } else {
            for (let i = 0; i < number; i++) {
                pieces.push(Piece.makeEmpty());
            }
        }
    }

    if (pieces.length !== 8) {
        throw new Error('Invalid FEN format: unexpected number of pieces on line.');
    }

    return pieces;
}

// Parse the pieces matrix according the FEN format.
function parsePieceMatrix(pieces_str) {
    const lines = pieces_str.split('/');
    if (lines.length !== 8) {
        throw new Error('Invalid FEN format: unexpected number of lines.');
    }

    const matrix = lines.reverse().map((x) => parsePieceLine(x));

    return matrix;
}

// Parse the castling state as per the FEN format.
// Returns the castling bit mask.
function parseCastlingState(str) {
    let mask = 0;

    if (str !== '-') {
        for (const ch of str.split('')) {
            switch (ch) {
                case 'K':
                    mask |= CASTLING_WHITE_KING;
                    break;
                case 'Q':
                    mask |= CASTLING_WHITE_QUEEN;
                    break;
                case 'k':
                    mask |= CASTLING_BLACK_KING;
                    break;
                case 'q':
                    mask |= CASTLING_BLACK_QUEEN;
                    break;
                default:
                    throw new Error('Invalid castling char: ' + ch);
            }
        }
    }

    return mask;
}

class ChessState {
    constructor(matrix, moveSide, castles, enPassant, halfMove, fullMove) {
        this.matrix = matrix;
        this.moveSide = moveSide;

        this.castles = castles;
        this.enPassant = enPassant;

        this.halfMove = halfMove;
        this.fullMove = fullMove;
    }

    // Create Chess State from a FEN format string.
    static create(fen) {
        const fields = fen.split(' ');
        if (fields.length !== 6) {
            throw new Error('Invalid FEN format: unexpected number of fields.');
        }

        const pieces = fields[0];
        const matrix = parsePieceMatrix(pieces);

        const moveSide = Color.parse(fields[1]);
        const castles = parseCastlingState(fields[2]);
        const enPassant = fields[3] === '-' ? null : Coord.create(fields[3]);

        if (enPassant) {
            const epRow = enPassant.row();
            if (epRow !== 2 && epRow !== 5)
                throw new Error('Invalid FEN format: invalid En Passant target: ' + fields[3]);
        }

        const halfMove = parseInt(fields[4]);
        const fullMove = parseInt(fields[5]);

        if (isNaN(halfMove) || isNaN(fullMove)) {
            throw new Error('Invalid FEN format: invalid move number.');
        }

        return new this(matrix, moveSide, castles, enPassant, halfMove, fullMove);
    }

    // Get chess piece at the specified coord.
    getPiece(coord) {
        return this.matrix[coord.row()][coord.col()];
    }

    getMoveSide() {
        return this.moveSide;
    }

    getEnPassant() {
        return this.enPassant;
    }

    getWhiteCastles() {
        let choices = [];
        if (this.castles & CASTLING_WHITE_KING) choices.push('O-O');
        if (this.castles & CASTLING_WHITE_QUEEN) choices.push('O-O-O');
        return choices.join(' ');
    }

    getBlackCastles() {
        let choices = [];
        if (this.castles & CASTLING_BLACK_KING) choices.push('O-O');
        if (this.castles & CASTLING_BLACK_QUEEN) choices.push('O-O-O');
        return choices.join(' ');
    }

    getHalfMoveNumber() {
        return this.halfMove;
    }

    getFullMoveNumber() {
        return this.fullMove;
    }

    move(srcCoord, tgtCoord) {
        return doMove(this, srcCoord, tgtCoord);
    }

    // Execute a move by its algebraic notation.
    move1(label) {
        const move = this.recreateMove(label);
        const result = doMove(this, move.source(), move.target());
        // TODO: perhaps verify the most-move state against the label?
        if (result === null) throw new Error('Failed to execute move: ' + label);
        return result.newBoard;
    }

    // Execute a list of moves.
    moveN(moves) {
        let bs = this;

        for (const m of moves) {
            bs = bs.move1(m);
        }

        return bs;
    }

    // Check whether it is valid to move the piece from *src* to *tgt*.
    canMove(srcCoord, tgtCoord) {
        return doMove(this, srcCoord, tgtCoord, true) !== null;
    }

    allMoves(src) {
        let moves = [];

        for (let row = 0; row < 8; row++) {
            for (let col = 0; col < 8; col++) {
                if (row === src.row() && col === src.col()) continue;
                const tgt = new Coord(row, col);
                if (this.canMove(src, tgt)) {
                    moves.push(tgt);
                }
            }
        }

        return moves;
    }

    // Check whether it is valid to select the square/piece at *coord*.
    //
    // Returns true if the square has a piece, which has the same color
    // as the current moveSide.
    //
    // Note: does not check whether the piece has any legal moves.
    canSelect(coord) {
        const piece = this.getPiece(coord);

        return !Piece.isEmpty(piece) && Piece.getColor(piece) === this.moveSide;
    }

    // Recreate the Move object by parsing the input label.
    // Pre-condition: label is a valid move for the current board state.
    recreateMove(label) {
        return createMoveFromLabel(this.matrix, this.moveSide, label);
    }
}

// Check whether a rider piece (rook, bishop, or queen) can move
// from srcCoord to tgtCoord. In other words, check whether there
// is a piece in between.
//
// Also used by pawn move from the base rank.
//
// Pre-condition: srcCoord & tgtCoord is on a straight line.
function checkRiderMove(matrix, srcCoord, tgtCoord) {
    const srcRow = srcCoord.row();
    const srcCol = srcCoord.col();
    const tgtRow = tgtCoord.row();
    const tgtCol = tgtCoord.col();

    const rowStride = Math.sign(tgtRow - srcRow);
    const colStride = Math.sign(tgtCol - srcCol);

    let row = srcRow + rowStride;
    let col = srcCol + colStride;

    for (; row !== tgtRow || col !== tgtCol; row += rowStride, col += colStride) {
        const piece = matrix[row][col];
        if (Piece.notEmpty(piece)) {
            return false;
        }
    }

    return true;
}

// Check whether the *piece* can move from *srcCoord* to *tgtCoord*.
//
// If *isCapture* == true and *piece* is a pawn, check whether a capture
// is possible.
//
// Does not handle special moves: castling or en passant.
// Does not consider check conditions.
function canMoveRaw(matrix, piece, srcCoord, tgtCoord, isCapture) {
    const rowDelta = tgtCoord.row() - srcCoord.row();
    const rowDeltaAbs = Math.abs(rowDelta);

    const colDelta = tgtCoord.col() - srcCoord.col();
    const colDeltaAbs = Math.abs(colDelta);

    //
    if (Piece.isKnight(piece)) {
        return rowDelta !== 0 && colDelta !== 0 && rowDeltaAbs + colDeltaAbs === 3;
    }

    //
    else if (Piece.isKing(piece)) {
        return rowDeltaAbs <= 1 && colDeltaAbs <= 1;
    }

    //
    else if (Piece.isRook(piece)) {
        return (rowDelta === 0 || colDelta === 0) && checkRiderMove(matrix, srcCoord, tgtCoord);
    }

    //
    else if (Piece.isBishop(piece)) {
        return rowDeltaAbs === colDeltaAbs && checkRiderMove(matrix, srcCoord, tgtCoord);
    }

    //
    else if (Piece.isQueen(piece)) {
        return (
            (rowDelta === 0 || colDelta === 0 || rowDeltaAbs === colDeltaAbs) &&
            checkRiderMove(matrix, srcCoord, tgtCoord)
        );
    }

    //
    else if (Piece.isPawn(piece)) {
        const isWhite = Piece.isWhite(piece);
        const moveDir = isWhite ? 1 : -1;
        const baseRank = isWhite ? 1 : 6; // Rank Index is zero based.

        if (isCapture) {
            return colDeltaAbs === 1 && rowDelta === moveDir;
        } else {
            // Require both on the column.
            if (colDelta !== 0) return false;

            // Single step move.
            if (rowDelta === moveDir) return true;

            // Double step move from the base rank.
            if (srcCoord.row() === baseRank && rowDelta === moveDir * 2) {
                if (checkRiderMove(matrix, srcCoord, tgtCoord)) return true;
            }

            // Otherwise, not a valid move.
            return false;
        }
    }

    //
    else {
        throw new Error('Unreachable.');
    }
}

// Check whether the *piece* at *srcCoord* attacks the piece or square at *tgtCoord*.
// Does not handle special captures: en passant.
// Does not consider check conditions.
function canCaptureRaw(matrix, piece, srcCoord, tgtCoord) {
    return canMoveRaw(matrix, piece, srcCoord, tgtCoord, true);
}

// Check EnPassant capture.
// Pre-condition: shall only be called when normal move/capture is not valid.
//
// Returns the e.p. capture square if allowed by the rule, or null otherwise.
function canCaptureEnPassant(board, piece, srcCoord, tgtCoord) {
    // Check the e.p. capture rules.
    //  - the move piece is pawn.
    //  - the current EnPassant state, that is, the current e.p. square, is not null.
    //  - target square === e.p. square.
    //  - source square can attack the target square.
    if (
        board.enPassant &&
        Coord.equal(tgtCoord, board.enPassant) &&
        Piece.isPawn(piece) &&
        canCaptureRaw(board.matrix, piece, srcCoord, tgtCoord)
    ) {
        // The enemy piece to be captured e.p.
        const col = tgtCoord.col();
        const row = srcCoord.row();
        return new Coord(row, col);
    } else {
        return null;
    }
}

// Create the post move matrix.
function getPostMoveMatrix(matrix, srcCoord, tgtCoord, epVictim, doCastle) {
    const srcRow = srcCoord.row();
    const tgtRow = tgtCoord.row();
    const srcCol = srcCoord.col();
    const tgtCol = tgtCoord.col();

    let newMatrix = matrix.map((arr, idx) => {
        if (idx === srcRow || idx === tgtRow || (epVictim !== null && idx === epVictim.row())) {
            return arr.slice();
        } else {
            return arr;
        }
    });

    newMatrix[tgtRow][tgtCol] = newMatrix[srcRow][srcCol];
    newMatrix[srcRow][srcCol] = Piece.makeEmpty();

    // In case of En Passant capture, remove the captured piece.
    if (epVictim) {
        newMatrix[epVictim.row()][epVictim.col()] = Piece.makeEmpty();
    }

    // In cast of castling, move the rook piece.
    if (doCastle) {
        const colDist = tgtCol - srcCol;
        const rookCol = colDist === 2 ? 7 : 0;
        const jumpedOverCol = (srcCol + tgtCol) / 2;
        newMatrix[srcRow][jumpedOverCol] = newMatrix[srcRow][rookCol];
        newMatrix[srcRow][rookCol] = Piece.makeEmpty();
    }

    return newMatrix;
}

// Returns the castles state after a move.
// Basically if a piece moved from the King or Rook square, mark the relevant
// castling as no long possible.
function getPostMoveCastles(castles, srcCoord) {
    if (castles === 0) return castles;

    const row = srcCoord.row();
    const col = srcCoord.col();

    if (row !== 0 && row !== 7) return castles;

    let mask = castles;

    if (col === 4 || col === 7) {
        // King or right Rook.
        const bit = row === 0 ? CASTLING_WHITE_KING : CASTLING_BLACK_KING;
        mask &= ~bit;
    }

    if (col === 4 || col === 0) {
        // King or left Rook.
        const bit = row === 0 ? CASTLING_WHITE_QUEEN : CASTLING_BLACK_QUEEN;
        mask &= ~bit;
    }

    return mask;
}

// Return the EnPassant state after moving the piece.
// Returns either the e.p. square or null.
function getPostMoveEnPassantState(srcPiece, srcCoord, tgtCoord) {
    if (!Piece.isPawn(srcPiece)) return null;
    if (srcCoord.col() !== tgtCoord.col()) return null;
    if (Math.abs(srcCoord.row() - tgtCoord.row()) !== 2) return null;

    const row = (srcCoord.row() + tgtCoord.row()) / 2;
    const col = srcCoord.col();

    return new Coord(row, col);
}

// Returns true iff the tgtCoord is attacked by the side.
// Pre-condition: tgtCoord is either empty of occupied by the opposite color.
function isSquareAttackedBy(matrix, tgtCoord, side) {
    for (let row = 0; row < 8; row++) {
        for (let col = 0; col < 8; col++) {
            const piece = matrix[row][col];

            if (Piece.isEmpty(piece) || Piece.getColor(piece) !== side) continue;

            const srcCoord = new Coord(row, col);
            if (Coord.equal(srcCoord, tgtCoord)) continue;

            if (canCaptureRaw(matrix, piece, srcCoord, tgtCoord)) return true;
        }
    }

    return false;
}

// Find the King of the required color.
// Pre-condition: there is either one or zero King.
//
// Returns its coord or null if not found.
function findKing(matrix, color) {
    const desired = Piece.makeKing(color);

    for (let row = 0; row < 8; row++) {
        for (let col = 0; col < 8; col++) {
            if (matrix[row][col] === desired) return new Coord(row, col);
        }
    }

    return null;
}

// Returns true if the King of the moveSide is in check.
function isInCheck(matrix, moveSide) {
    // Find the King.
    const kingCoord = findKing(matrix, moveSide);

    // XXX: allow incomplete board, where King does not exist.
    if (!kingCoord) return false;

    return isSquareAttackedBy(matrix, kingCoord, Color.getOpposite(moveSide));
}

// Check whether it is a valid castle move.
function canCastle(board, piece, srcCoord, tgtCoord) {
    // Requires the moving piece is King, and castling is possible currently.
    if (board.castles === 0 || !Piece.isKing(piece)) return false;

    const matrix = board.matrix;
    const moveSide = Piece.getColor(piece);

    // Verify the move positions.
    const colDist = tgtCoord.col() - srcCoord.col();
    if (Math.abs(colDist) !== 2) return false;
    if (srcCoord.row() !== tgtCoord.row()) return false;

    // Figure out the castling kind.
    const castleKind = Piece.isWhite(piece)
        ? colDist === 2
            ? CASTLING_WHITE_KING
            : CASTLING_WHITE_QUEEN
        : colDist === 2
        ? CASTLING_BLACK_KING
        : CASTLING_BLACK_QUEEN;

    // Check whether the castling kind is allowed.
    if ((board.castles & castleKind) === 0) return false;

    // Verify the Rook piece.
    const row = srcCoord.row();
    const kingCol = srcCoord.col();
    if (kingCol !== 4) {
        throw new Error('Bug: castling King not at e-file: ' + srcCoord.label());
    }
    const rookCol = colDist === 2 ? 7 : 0;
    if (rookCol !== 0 && rookCol !== 7) {
        const rookCoord = new Coord(row, rookCol);
        throw new Error('Bug: castling Rook on the wrong file: ' + rookCoord.label());
    }

    const rookPiece = matrix[row][rookCol];
    if (
        Piece.isEmpty(rookPiece) ||
        !Piece.isRook(rookPiece) ||
        Piece.getColor(rookPiece) !== moveSide
    ) {
        throw new Error('Bug: castling Rook piece is invalid: ' + rookPiece);
    }

    // Check there are no pieces between the King and the Rook.
    const stride = Math.sign(colDist);
    for (let col = kingCol + stride; col !== rookCol; col += stride) {
        if (!Piece.isEmpty(matrix[row][col])) return false;
    }

    // Check the check conditions.
    const oppositeSide = Color.getOpposite(moveSide);
    const jumpedOverCoord = new Coord(row, kingCol + stride);
    if (
        isSquareAttackedBy(matrix, srcCoord, oppositeSide) ||
        isSquareAttackedBy(matrix, jumpedOverCoord, oppositeSide) ||
        isSquareAttackedBy(matrix, tgtCoord, oppositeSide)
    ) {
        return false;
    }

    // All clear.
    return true;
}

// Move a piece from the *src* grid to the *tgt* grid.
// Returns a new ChessState object is successful, or null to indicate a failure.
//
// Example: src="e2" tgt="e4".
//
// FIXME: the followings are not yet handled:
//  - promotion.
//
// The nop parameter (if true) indicates that the caller is only interested to know whether
// the move is valid, but not to the new board state. In which case, it will return a non-null
// reference to a dummy object.
function doMove(board, srcCoord, tgtCoord, nop = false) {
    //console.log(`move: src=${src}, tgt=${tgt}.`);

    // First verify the pre-conditions:
    //  - src shall not be equal to tgt.
    //  - srcCoord has a piece whose color equals moveSide.
    if (Coord.equal(srcCoord, tgtCoord)) {
        throw new Error('move: srcCoord === tgtCoord.');
    }

    const srcPiece = board.getPiece(srcCoord);
    if (Piece.isEmpty(srcPiece) || Piece.getColor(srcPiece) !== board.moveSide) {
        throw new Error('move: invalid source piece.');
    }

    // Cannot move if the target square has a piece with the same color.
    const tgtPiece = board.getPiece(tgtCoord);
    if (!Piece.isEmpty(tgtPiece) && Piece.getColor(tgtPiece) === board.moveSide) {
        return null;
    }

    // Check whether it is possible to do a normal move or capture.
    const isCapture = !Piece.isEmpty(tgtPiece);
    const canMoveNormal = canMoveRaw(board.matrix, srcPiece, srcCoord, tgtCoord, isCapture);

    // Check EnPassant capture, only when normal move/capture is not possible.
    const epVictim = canMoveNormal
        ? null
        : canCaptureEnPassant(board, srcPiece, srcCoord, tgtCoord);
    const canCaptureEp = epVictim !== null;

    // Check whether it is a castle move.
    const canDoCastle =
        !canMoveNormal && !canCaptureEp && canCastle(board, srcPiece, srcCoord, tgtCoord);

    if (!canMoveNormal && !canCaptureEp && !canDoCastle) return null;

    // Make the move.
    const newMatrix = getPostMoveMatrix(board.matrix, srcCoord, tgtCoord, epVictim, canDoCastle);

    // It is an invalid move if it leaves or moves the King in check.
    if (isInCheck(newMatrix, board.moveSide)) return null;

    // In NOP mode, do not bother to generate a new board state.
    if (nop) {
        return { dummy_board: true };
    }

    const newMoveSide = Color.getOpposite(board.moveSide);

    // Generate the new castles state.
    const newCastles = getPostMoveCastles(board.castles, srcCoord);

    // Generate the new En Passant state.
    const newEnPassant = getPostMoveEnPassantState(srcPiece, srcCoord, tgtCoord);

    // Increment the half move number, or reset in the case of capture or Pawn move.
    const newHalfMove = isCapture || Piece.isPawn(srcPiece) ? 0 : board.halfMove + 1;

    // Increment the full move number after a black move.
    const newFullMove = Color.isBlack(board.moveSide) ? board.fullMove + 1 : board.fullMove;

    const isCheck = isInCheck(newMatrix, newMoveSide);
    const move = new Move(srcPiece, srcCoord, tgtCoord, {
        isCapture: isCapture || canCaptureEp,
        isEp: canCaptureEp,
        isCastling: canDoCastle,
        isCheck: isCheck,
    });
    //console.log(move.label());

    const newBoard = new ChessState(
        newMatrix,
        newMoveSide,
        newCastles,
        newEnPassant,
        newHalfMove,
        newFullMove
    );

    return { newBoard: newBoard, move: move };
}

// TODO: the followings are not yet processed.
//  - e.p. capture.
//  - castling.
function createMoveFromLabel(matrix, side, label) {
    const pattern = /^([KQRBNP]?)([a-h]?)([1-8]?)(x?)([a-h][1-8])(e\.p\.)?(\+?)$/;

    const matched = pattern.exec(label);
    if (!matched) {
        throw new Error('Invalid move: ' + label);
    }

    //console.log(matched);

    const [, mPiece, mSrcFile, mSrcRank, mCapture, mTgt, mEpCapture, mCheck] = matched;

    // Verify the piece.
    const pieceKind = mPiece === '' ? 'P' : mPiece;
    const piece = Piece.parse(Color.isWhite(side) ? pieceKind : pieceKind.toLowerCase());

    // Verify the target coord/piece.
    const tgtCoord = Coord.create(mTgt);
    const tgtPiece = matrix[tgtCoord.row()][tgtCoord.col()];

    const isCapture = mCapture === 'x';
    const isEpCapture = mEpCapture === 'e.p.';
    const isCheck = mCheck === '+';

    if (isCapture && !isEpCapture) {
        // A capture, which is not En Passant. Require the target square to be not empty.
        if (Piece.isEmpty(tgtPiece) || Piece.getColor(tgtPiece) === side)
            throw new Error(
                'Move is a capture but target square is empty or is not opposite color.'
            );
    } else {
        // Not a capture, or is an En Passant capture. Require the target square to be empty.
        if (!Piece.isEmpty(tgtPiece))
            throw new Error('Move is not a capture but target square is not empty.');
    }

    const srcCoord = findCandidateSource(matrix, piece, isCapture, tgtCoord, mSrcFile, mSrcRank);

    if (srcCoord === null) {
        throw new Error('Cannot find candidate source square for move: ' + label);
    }

    return new Move(piece, srcCoord, tgtCoord, {
        isCapture: isCapture,
        isCheck: isCheck,
    });
}

// Find a candidate piece of side that can move/capture to the tgt square.
// srcFile & srcRank may be empty. If they are not, they are to disambiguate
// the source square.
//
// Returns the Coord of source square if found, or null otherwise.
function findCandidateSource(matrix, piece, isCapture, tgtCoord, srcFile, srcRank) {
    let row_begin = 0;
    let row_end = 7;
    let col_begin = 0;
    let col_end = 7;

    if (srcFile !== '') {
        const col = srcFile.charCodeAt(0) - 'a'.charCodeAt(0);
        if (col < 0 || col > 7) throw new Error('Invalid src file: ' + srcFile);
        col_begin = col_end = col;
    }

    if (srcRank !== '') {
        const row = srcRank.charCodeAt(0) - '1'.charCodeAt(0);
        if (row < 0 || row > 7) throw new Error('Invalid src rank: ' + srcFile);
        row_begin = row_end = row;
    }

    let candidateCoord = null;

    for (let row = row_begin; row <= row_end; ++row)
        for (let col = col_begin; col <= col_end; ++col) {
            const p = matrix[row][col];
            if (p !== piece) continue;

            // Found a piece of the correct kind and side.
            // Check whether this can move/capture to the target square.
            const src = new Coord(row, col);
            if (!canMoveRaw(matrix, p, src, tgtCoord, isCapture)) continue;

            // Found a candidate piece.
            if (candidateCoord !== null) throw new Error('Move is ambiguous.');
            else candidateCoord = src;
        }

    return candidateCoord;
}

// Create Chess State from a FEN format string.
// If *fen* is null, create the starting position.
function createChessState(fen) {
    if (fen) {
        return ChessState.create(fen);
    } else {
        return ChessState.create(FEN_STARTING_POSITION);
    }
}

export default createChessState;
